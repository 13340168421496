import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [9,2,5,7];

export const dictionary = {
		"/agent": [~23,[9],[10]],
		"/agent/dev": [24,[9],[10]],
		"/agent/orders": [~25,[9],[10]],
		"/agent/routes": [~26,[9],[10]],
		"/agent/routes/[blueprint]": [~27,[9],[10]],
		"/auth/auth0/callback": [~28,[],[11]],
		"/[[lang=language]]/booking": [~14,[2],[3,4]],
		"/[[lang=language]]/booking/[booking]": [~15,[2,,5],[3,4]],
		"/[[lang=language]]/booking/[booking]/passengers": [~16,[2,,5],[3,4]],
		"/[[lang=language]]/booking/[booking]/payment/[payment]": [~17,[2,,5],[3,4]],
		"/[[lang=language]]/booking/[booking]/tickets": [~18,[2,,5],[3,4]],
		"/[[lang=language]]/order/[order]": [~19,[2,6],[3]],
		"/[[lang=language]]/routes": [~20,[2],[3]],
		"/[[lang=language]]/routes/[route]/[[blueprint]]": [~21,[2],[3]],
		"/[[lang=language]]/search": [~22,[2,7],[3,8]],
		"/[[lang=language]]": [~12,[2],[3]],
		"/[[lang=language]]/[slug]": [~13,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';